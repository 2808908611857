import m from "mithril";
import UserOperatingPermissionsComponent from "./mithrilComponents/UserOperatingPermissionsComponent";
import TUserOperatingPermissions from "./TUserOperatingPermissions";

export default class UserOperatingPermissions {

    constructor(
        private readonly $component: JQuery,
        private readonly componentName: string,
    ) {
        let data: TUserOperatingPermissions;
        try {
            const raw = this.$component.find('script[type="application/json"]').text();
            data = JSON.parse( raw );
        } catch (err) {
            console.error(`Cannot parse JSON data for UserOperatingPermissions(${this.componentName}) component.`);
            throw err;
        }


        m.mount(this.$component[0], new UserOperatingPermissionsComponent(data));
    }

}
