import TDataset from "../../../types/TDataset";
import TCheckbox from "../../../types/TCheckbox";

export default class ValueList {

    private readonly changeList: Map<string, true> = new Map();

    public static fromCheckboxes(checkboxes: TCheckbox[]): ValueList {
        const values = new Map<string, boolean>();

        checkboxes.forEach((checkbox) => {
            values.set(String(checkbox.id), checkbox.checked);
        });

        return new ValueList(values);
    }

    public static fromDatasets(datasets: TDataset[]): ValueList {
        const values = new Map<string, boolean>();

        datasets.forEach(dataset => {
            dataset.checkboxes.forEach((checkbox) => {
                values.set(String(checkbox.id), checkbox.checked);
            });
        });

        return new ValueList(values);
    }

    private constructor(
        private readonly values: Map<string, boolean>
    ) { }

    public toggle(id: number|string): void {
        id = String(id);
        if (this.changeList.has(id)) {
            this.changeList.delete(id);
        } else {
            this.changeList.set(id, true);
        }
    }

    public get(id: number|string): boolean {
        id = String(id);
        const originalValue = this.values.get(id);
        const wasChanged = this.changeList.has(id);

        return wasChanged ? !originalValue : originalValue;
    }

    public set(id: number|string, value: boolean): void {
        if (this.get(id) !== value) {
            this.toggle(id);
        }
    }

    public changed(id: number|string): boolean {
        return this.changeList.has( String(id) );
    }


    public count(): number {
        return this.changeList.size;
    }

    public all(): {[id: string]: boolean} {
        const result = {};
        this.values.forEach((v, id ) => result[id] = this.get(id));

        return result;
    }

    public checked(): string[] {
        const result = [];
        this.values.forEach((v, id) => {
            if (this.get(id)) {
                result.push(id);
            }
        });

        return result;
    }

    public apply(): void {
        this.changeList.forEach((v, id) => {
            this.values.set(id, !this.values.get(id));
        });
        this.changeList.clear();
    }

    public reset(): void {
        this.changeList.clear();
    }
}