import m, {Vnode} from "mithril";
import TDataset from "../../../types/TDataset";
import UserDataPermissionsComponent from "./UserDataPermissionsComponent";
import translator from "../../../../../translator";
import CheckboxComponent from "../../../mithrilComponents/CheckboxComponent";
import normalizeString from "../../../../../Board/src/utilities/normalizeString";

export default class DatasetDetailComponent {

    static readonly COLLAPSED_LENGTH: number = 3;
    static readonly DETAIL_RESULT_COLLAPSED_LENGTH: number = 50;
    static readonly DETAIL_ALL_COLLAPSED_LENGTH: number = 250;

    private self;
    private limit = 0;
    public query = '';
    private readonly checkboxes: CheckboxComponent[];

    constructor(
        private readonly container: UserDataPermissionsComponent,
        public readonly dataset: TDataset,
        private readonly backlink: boolean = false,
    ) {
        this.self = this;
        this.checkboxes = dataset.checkboxes.map( checkbox => new CheckboxComponent(
            checkbox,
            this.container.valueList
        ) );
    }

    public collapse(count?: number): void {
        this.self.limit = count ?? DatasetDetailComponent.COLLAPSED_LENGTH;
    }

    public expand(full = false): void {
        this.self.limit = full ? 0 : this.limit+5;
    }

    public matchQuery(query: string): boolean {
        return this.matchSelf(query) || this.matchCheckboxes(query);
    }

    public matchSelf(query: string): boolean {
        return normalizeString(this.dataset.title).includes(query);
    }

    public matchCheckboxes(query: string): boolean {
        return this.dataset.checkboxes.some(checkbox => normalizeString(checkbox.title).includes(query) || normalizeString(checkbox.description ?? '').includes(query));
    }

    public getFilteredCheckboxes(): CheckboxComponent[] {
        let count = 0;
        return this.query ? this.checkboxes.filter(ch => {
            if (this.limit && count > this.limit+1) {
                return false;
            }
            const title = normalizeString(ch.checkbox.title);
            if (title.includes(this.query)) {
                count++;
                return true;
            }
            const description = normalizeString(ch.checkbox.description ?? '');
            if (description.includes(this.query)) {
                count++;
                return true;
            }
            return false;
        }) : this.checkboxes;
    }

    private viewModified(): Vnode|null {
        const modified = this.dataset.checkboxes.some(checkbox => this.container.valueList.changed(checkbox.id));

        if (modified) {
            return m('.badge.badge-accent.fs-9.px-2.py-0', {}, translator.translate('modified'));
        } else if (this.backlink) {
            return m('.btn.btn-sm.btn-flat-secondary', {
                onclick: () => this.container.onModalCancel(),
                title: translator.translate('back'),
            }, [
                m('i.icon.icon-xs.ico-back.mr-0'),
                m('span', {}, translator.translate('back'))
            ]);
        }
        return null;
    }

    private viewCheckboxes(): Vnode|Vnode[] {
        let checkboxes = this.getFilteredCheckboxes();

        if (!checkboxes.length) {
            return m('.', {}, translator.translate('empty_result', {query: this.query}));
        }

        const resultSize = checkboxes.length;
        const shortenResult = this.limit && resultSize > this.limit+1;

        if (shortenResult) {
            checkboxes = checkboxes.slice(0,this.limit);
        }

        const vnodes = checkboxes.map(ch => m(ch));

        if (shortenResult) {
            if (this.limit >= DatasetDetailComponent.DETAIL_RESULT_COLLAPSED_LENGTH) {
                vnodes.push( m('a', {
                    href: 'javascript:void(0);',
                    onclick: () => this.expand(true),
                }, translator.translate('show_all')));
            } else {
                vnodes.push( m('a', {
                    href: 'javascript:void(0);',
                    onclick: () => this.expand(),
                }, translator.translate('show_more_results')));
            }
        }

        return vnodes;
    }

    public view(): Vnode {
        return m('.', {}, [
            m('.dataset-group.open.d-flex.justify-content-center.flex-row.flex-nowrap.px-3.py-2', {}, [
                m('.d-flex.flex-column.justify-content-center.flex-grow-1.mr-3', [
                    m('label.font-weight-bold.fs-6.mb-0', {}, this.dataset.title),
                ]),
                this.viewModified(),

            ]),
            m('.dataset-list.p-3', {}, this.viewCheckboxes()),
        ]);
    }

}
