import m, {Vnode} from "mithril";
import TDataset from "../../../types/TDataset";
import translator from "../../../../../translator";
import UserDataPermissionsComponent from "./UserDataPermissionsComponent";

export default class DatasetComponent {

    constructor(
        private readonly container: UserDataPermissionsComponent,
        private readonly dataset: TDataset,
    ) {
    }


    private viewEmptyControls(): Vnode {
        return m('.controls.d-flex', {}, [
            m('button.btn.btn-flat-secondary', {
                onclick: () => this.container.openDetail(this.dataset),
                title: translator.translate('edit'),
            }, [
                m('i.icon.icon-xs.ico-plus-circle')
            ])
        ])
    }

    private viewEditControls(activeOptionsCount: number): Vnode {
        return m('.controls.d-flex.justify-content-center.align-items-center.flex-row.flex-nowrap.gap-1', {}, [
            m('small.text-secondary.mr-3', {}, `(${activeOptionsCount})`),
            m('button.btn.btn-flat-secondary', {
                onclick: () => this.container.openDetail(this.dataset),
                title: translator.translate('edit'),
            }, [
                m('i.icon.icon-xs.ico-pen')
            ]),
            m('button.btn.btn-flat-danger', {
                onclick: () => {
                    if (confirm(translator.translate('are_you_sure'))) {
                        this.dataset.checkboxes.forEach(checkbox => {
                            this.container.valueList.set(checkbox.id, false);
                        })
                        this.container.onModalSave();
                    }
                },
                title: translator.translate('delete'),
            }, [
                m('i.icon.icon-xs.ico-delete')
            ])
        ])
    }

    private viewDescription(): Vnode|null {
        const description = this.dataset.checkboxes
            .filter(checkbox => this.container.valueList.get(checkbox.id))
            .map(checkbox => checkbox.title)
            .filter(title => title)
            .join(', ');

        return description ? m('small.text-secondary', {}, description) : null;
    }

    public view(): Vnode {
        const activeOptionsCount = this.dataset.checkboxes.filter(ch => this.container.valueList.get(ch.id)).length;

        return m('.dataset-group.d-flex.justify-content-center.flex-row.flex-nowrap.px-3.py-2', {}, [
            m('.d-flex.flex-column.justify-content-center.flex-grow-1.mr-3', {}, [
                m('label.font-weight-bold.fs-6.mb-0', {}, this.dataset.title),
                this.viewDescription(),
            ]),
            activeOptionsCount ? this.viewEditControls(activeOptionsCount) : this.viewEmptyControls(),
        ]);
    }
}