import m, {Vnode} from "mithril";
import translator from "../../../translator";

export default class SavedComponent {

    private self: SavedComponent;
    private timeout = 0;

    constructor(
        private readonly onFinish: () => void,
        private readonly timeout_ms = 3000,
    ) {
        this.self = this;
    }

    public oncreate() {
        this.self.timeout = window.setTimeout(() => {
            this.onFinish();
        }, this.timeout_ms);
    }

    public view(): Vnode {
        return  m('.modal-body.d-flex.flex-column.justify-content-center.align-items-center.p-5', {}, [
            m('button.close.position-absolute', {
                type: 'button',
                style: 'top: 1rem; right: 1rem;',
                'aria-label': translator.translate('close'),
                onclick: () => {
                    clearTimeout(this.timeout);
                    this.onFinish();
                },
            }, [
                m('span', {
                    'aria-hidden': 'true'
                }, [
                    m.trust('&times;')
                ]),
            ]),
            m('img', {src: '/images/animated-success-icon.gif'}),
            m('h3.my-5', {}, translator.translate('successfully_saved')),
            m('button.btn.btn-primary', {
                onclick: () => {
                    clearTimeout(this.timeout);
                    this.onFinish();
                },
            }, translator.translate('continue')),
        ]);
    }
}