import m from "mithril";
import UserDataPermissionsComponent from "./mithrilComponents/UserDataPermissionsComponent";
import TUserDataPermissions from "./TUserDataPermissions";

export default class UserDataPermissions {

    constructor(
        private readonly $component: JQuery,
        private readonly componentName: string,
    ) {
        let data: TUserDataPermissions;
        try {
            const raw = this.$component.find('script[type="application/json"]').text();
            data = JSON.parse( raw );
        } catch (err) {
            console.error(`Cannot parse JSON data for UserOperatingPermissions(${this.componentName}) component.`);
            throw err;
        }

        m.mount(this.$component[0], new UserDataPermissionsComponent(data));

        this.attachSearchEvent();
    }

    /**
     * Rewrite CTRL + F / CMD + F while modal is opened
     */
    private attachSearchEvent(): void {
        const $modal = this.$component.closest('.modal');
        if (!$modal.length) {
            return;
        }

        const rewriteSearchOnPage = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'f') {
                const searchEl = this.$component.find('input[type="search"]')[0];
                if (searchEl) {
                    e.preventDefault();
                    searchEl.focus();
                }
            }
        }

        const onShownModal = () => {
            window.addEventListener('keydown', rewriteSearchOnPage);
        }

        const onHiddenModal = () => {
            window.removeEventListener('keydown', rewriteSearchOnPage);
            $modal.off('shown.bs.modal', onShownModal);
            $modal.off('hidden.bs.modal', onHiddenModal);
        }

        $modal.on('shown.bs.modal', onShownModal);
        $modal.on('hidden.bs.modal', onHiddenModal);
    }



}
