import TUserDataPermissions from "../TUserDataPermissions";
import m, {Vnode} from "mithril";
import translator from "../../../../../translator";
import DatasetComponent from "./DatasetComponent";
import TDataset from "../../../types/TDataset";
import DatasetDetailComponent from "./DatasetDetailComponent";
import ValueList from "../model/ValueList";
import normalizeString from "../../../../../Board/src/utilities/normalizeString";
import SavedComponent from "../../../mithrilComponents/SavedComponent";


export default class UserDataPermissionsComponent {

    public readonly valueList: ValueList;
    public saving: boolean = false;
    private searchQuery: string;

    private self;

    private readonly datasetDetails: DatasetDetailComponent[] = [];
    private datasetDetail: DatasetDetailComponent|null = null;

    private readonly datasets: DatasetComponent[];

    private readonly saved: SavedComponent|null = null;

    constructor(
        private readonly setting: TUserDataPermissions
    ) {
        this.self = this;
        this.valueList = ValueList.fromDatasets(setting.datasets);
        this.datasets = setting.datasets.map(dataset => new DatasetComponent(this, dataset));
        this.datasetDetails = setting.datasets.map(dataset => new DatasetDetailComponent(this, dataset));
    }

    public openDetail(dataset: TDataset): void {
        this.self.datasetDetail = new DatasetDetailComponent(this, dataset, true);
        this.self.datasetDetail.collapse(DatasetDetailComponent.DETAIL_ALL_COLLAPSED_LENGTH);
    }

    private closeModal() {
        $('.modal.fade.show').modal('hide');
    }

    public onModalClose(): void {
        if (!this.valueList.count() || confirm(translator.translate('confirm_not_saved_continue'))) {
            this.closeModal();
        }
    }

    public onModalCancel(): void {
        if (!this.valueList.count() || confirm(translator.translate('confirm_cancel_not_saved'))) {
            this.self.datasetDetail = null;
            this.self.query = '';
            this.valueList.reset();
        }
    }

    public async onModalSave() {
        const onSuccess = () => {
            this.self.datasetDetail = null;
            this.setSearchQuery('');
            this.self.saved = new SavedComponent(() => {
                this.self.saved = null;
                m.redraw();
            });
        }
        if (!this.valueList.count()) {
            onSuccess();
            return;
        }

        this.self.saving = true;

        try {
            await m.request({
                url: this.setting.urls.save,
                method: 'POST',
                body: this.valueList.checked(),
            });
            this.valueList.apply();
            onSuccess();
        } catch (e) {
            alert('Saving error!');
        } finally {
            this.self.saving = false;
            m.redraw();
        }
    }

    private setSearchQuery(query: string): void {
        this.searchQuery = normalizeString(query);
        if (this.datasetDetail) {
            this.datasetDetail.query = this.searchQuery;
            this.datasetDetail.collapse(this.searchQuery ? DatasetDetailComponent.DETAIL_RESULT_COLLAPSED_LENGTH : DatasetDetailComponent.DETAIL_ALL_COLLAPSED_LENGTH);
        }
        this.datasetDetails.forEach(detail => {
            detail.query = detail.matchCheckboxes(query) ? query : '';
            Boolean(this.searchQuery || this.valueList.count()) ? detail.collapse() : detail.expand(true);
        });
    }



    public viewSaved(): Vnode {
        return m('.modal-content.user-permissions', {}, [
            m(this.saved),
        ]);
    }

    public viewSaving(): Vnode {
        return m('.modal-content.user-permissions', {}, [
            m('.modal-body', {}, translator.translate('saving')),
        ]);
    }

    private viewContent(): Vnode {
        if (this.datasetDetail) {
            return m(this.datasetDetail);
        }
        if (this.searchQuery || this.valueList.count()) {
            const details = this.datasetDetails.filter(detail => detail.matchQuery(this.searchQuery));
            if (details.length) {
                return m('.', {}, details.map(dataset => m(dataset)));
            } else {
                return m('.px-3.pt-2.pb-3', {}, translator.translate('empty_result', {query: this.searchQuery}));
            }
        }

        return m('.', {}, this.datasets.map(dataset => m(dataset)));
    }


    public view(): Vnode {
        if (this.saved) {
            return this.viewSaved();
        }

        if (this.saving) {
            return this.viewSaving()
        }

        const count = this.valueList.count();

        return m('.modal-content.user-permissions.UserOperatingPermissions', {}, [
            m('.modal-header', {}, [
                m('.', {}, [
                    m('h4.modal-title.font-weight-bold', {}, translator.translate('data_permissions')),
                    m('span.text-secondary', {}, this.setting.user.name),
                ]),
                m('button.close', {
                    type: 'button',
                    'aria-label': translator.translate('close'),
                    onclick: () => this.onModalClose(),
                }, [
                    m('span', {
                        'aria-hidden': 'true'
                    }, [
                        m.trust('&times;')
                    ]),
                ]),
            ]
            ),
            m('.modal-body',
                m('p.text-secondary.small', {}, translator.translate('data_permission_hint')),
                m('input.form-control', {
                    type: 'search',
                    placeholder: translator.translate('search'),
                    oninput: e => this.setSearchQuery(e.target.value),
                }),
            ),
            m('.modal-body.p-0',
                this.viewContent(),
            ),
            this.datasetDetail || count ? m('.modal-footer', {}, [
                m('button.btn.btn-primary', {
                    onclick: () => this.onModalSave(),
                    disabled: !count,
                }, translator.translate('save') + (count? ` (${count})`:'')),
                m('button.btn.btn-secondary', {
                    onclick: () => this.onModalCancel(),
                }, translator.translate('cancel')),
            ]) : null,
        ]);
    }

}
