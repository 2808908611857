import TUserOperatingPermissions from "../TUserOperatingPermissions";
import m, {Vnode} from "mithril";
import translator from "../../../../../translator";
import CheckboxComponent from "../../../mithrilComponents/CheckboxComponent";
import ValueList from "../../../UserDataPermissions/js/model/ValueList";
import SavedComponent from "../../../mithrilComponents/SavedComponent";

export default class UserOperatingPermissionsComponent {

    private self: UserOperatingPermissionsComponent;

    private saving: boolean = false;
    private saved: SavedComponent|null = null;

    private readonly checkboxes: CheckboxComponent[];
    private readonly valueList: ValueList;

    constructor(
        private readonly setting: TUserOperatingPermissions
    ) {
        this.self = this;

        this.valueList = ValueList.fromCheckboxes(setting.checkboxes);
        this.checkboxes = setting.checkboxes.map(
            checkbox => new CheckboxComponent(
                checkbox,
                this.valueList
            )
        );
    }

    private closeModal() {
        $('.modal.fade.show').modal('hide');
    }

    private onModalCancel(): void {
        if (!this.valueList.count() || confirm(translator.translate('confirm_not_saved_continue'))) {
            this.closeModal();
        }
    }

    private async onModalSave() {
        if (!this.valueList.count()) {
            this.closeModal();
            return;
        }

        this.saving = true;

        try {
            await m.request({
                url: this.setting.urls.save,
                method: 'POST',
                body: this.valueList.all(),
            });
            this.self.saved = new SavedComponent(() => this.closeModal());
            m.redraw();

        } catch (e) {
            alert('Saving error!');
        } finally {
            this.saving = false;
        }
    }

    private viewSaving(): Vnode {
        return m('.modal-content.user-permissions', {}, [
            m('.modal-body', {}, translator.translate('saving')),
        ]);
    }

    private viewSaved(): Vnode {
        return m('.modal-content.user-permissions', {}, [
            m(this.saved),
        ]);
    }

    public view(): Vnode {
        if (this.saved) {
            return this.viewSaved();
        }

        if (this.saving) {
            return this.viewSaving();
        }

        const count = this.valueList.count();

        return m('.modal-content.user-permissions', {}, [
            m('.modal-header', {}, [
                m('.', {}, [
                    m('h4.modal-title.font-weight-bold', {}, translator.translate('operation_permissions')),
                    m('span.text-secondary', {}, this.setting.user.name),
                ]),
                m('button.close', {
                    type: 'button',
                    'aria-label': translator.translate('close'),
                    onclick: () => this.onModalCancel(),
                }, [
                    m('span', {
                        'aria-hidden': 'true'
                    }, [
                        m.trust('&times;')
                    ]),
                ]),
            ]
            ),
            m('.modal-body',
                m('p.text-secondary.small.mb-0', {}, translator.translate('operation_permission_hint')),
            ),
            m('.modal-body',
                m('.', {}, this.checkboxes.map(checkbox => m(checkbox))),
            ),
            m('.modal-footer', {}, [
                m('button.btn.btn-primary', {
                    onclick: () => this.onModalSave(),
                    disabled: !count,
                }, translator.translate('save')+(count?` (${count})`:'')),
                m('button.btn.btn-secondary', {
                    onclick: () => this.onModalCancel(),
                }, translator.translate('close')),
            ]),
        ]);
    }

}
