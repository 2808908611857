import m, {Vnode} from "mithril";
import TCheckbox from "../types/TCheckbox";
import ValueList from "../UserDataPermissions/js/model/ValueList";

export default class CheckboxComponent {

    constructor(
        public readonly checkbox: TCheckbox,
        private readonly valueList: ValueList
    ) { }

    public view(): Vnode {

        const id = this.checkbox.id;
        const changed = this.valueList.changed(id) ? '.changed' : '';

        return m('.custom-control.custom-checkbox', {}, [
            m('input[type="checkbox"].custom-control-input'+changed, {
                id: 'operation_permissions_'+id,
                checked: this.valueList.get(id),
                onchange: () => this.valueList.toggle(id),
            }),
            m('label.custom-control-label.d-flex.flex-column', {
                'for': 'operation_permissions_'+id,
            }, [
                m('span.form-label', {}, this.checkbox.title),
                this.checkbox.description ? m('small.text-secondary', {}, this.checkbox.description) : null,
            ]),
        ])
    }

}
